import React from 'react'
import { useHeaderImage } from './image'
import SEO from '../seo'

export function PropertySEO({ property, locale }) {
  const title = property.attributes.description.title[locale]
  const description = property.attributes.description.description[locale]
  const headerImage = useHeaderImage({
    property,
    type: 'agency-website-thumbnail',
  })

  const image = headerImage?.url

  return (
    <SEO
      title={title}
      description={description}
      image={image}
      article
      locale={locale}
    />
  )
}

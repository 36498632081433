import React from 'react'
import Panel from '../panel'
import { FormattedMessage } from 'react-intl'
import { isPropertyInRegionWithPEB } from '../../utils/helpers'

export function EnergyPanel({ property }) {
  if (property.internal_type === 'project') {
    return null
  }

  const energyFeaturesGroups = ['energy', 'ecology', 'heating_cooling']
  const energyFeatures = energyFeaturesGroups.flatMap(
    (group) =>
      property.attributes.features?.[group]?.map(
        (feature) => `features.${group}.${feature}`
      ) ?? []
  )

  const epcValue = property.attributes.legal?.energy?.epc_value
  const epcCategory = property.attributes.legal?.energy?.epc_category
  const energyDpe = property.attributes.legal?.energy?.dpe
  const greenHouseEmissions =
    property.attributes.legal?.energy?.greenhouse_emissions

  const canShowEnergyFeatures = energyFeatures.length > 0
  const canShowEnergyPanel =
    epcValue || epcCategory || energyDpe || greenHouseEmissions

  if (!canShowEnergyFeatures && !canShowEnergyPanel) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="energy" />}>
      <div className="energy-panel__root">
        {canShowEnergyFeatures && (
          <div className="energy-panel__features">
            <ul className="c-feature-list c-feature-list--checked c-feature-list--split-2">
              {energyFeatures.map((featureId) => {
                return (
                  <li key={featureId} className="c-feature">
                    <span className="c-feature__description">
                      <FormattedMessage id={featureId} />
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {canShowEnergyPanel ? (
          <div className="energy-panel__energy">
            {/* dpe (EN="DPE") enum of A, B, C, D, E, F, G, not_applicable, empty */}
            {energyDpe && (
              <div className="c-key-number">
                <div className="c-key-number__value">
                  {energyDpe === 'empty' || energyDpe === 'not_applicable' ? (
                    <FormattedMessage id={`legal.value.${energyDpe}`} />
                  ) : (
                    energyDpe
                  )}
                </div>
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.dpe.label" />
                </div>
              </div>
            )}
            {/* END dpe */}

            {/* epc_value (EN="Energy Score") */}
            {epcValue && (
              <div className="o-grid__item u-2-of-4 c-key-number">
                <div className="c-key-number__value">
                  {epcValue} <small>kWh/m²</small>
                </div>
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.epc_value.label" />
                </div>
              </div>
            )}
            {/* END epc_value */}

            {/* epc_category (EN="EPC Level") enum of A++, A+, A, B, C, D, E, F, G */}
            {epcCategory && (
              <div className="o-grid__item u-2-of-4 c-key-number">
                {isPropertyInRegionWithPEB(property) ? (
                  <div className="c-key-number__value">
                    <img
                      src={`/img/peb_logos/peb_${epcCategory
                        ?.toLowerCase()
                        ?.replace(/\+/g, 'plus')}.png`}
                      alt={epcCategory}
                      height="20"
                    />
                  </div>
                ) : (
                  <div className="c-key-number__value">{epcCategory}</div>
                )}
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.epc_category.label" />
                </div>
              </div>
            )}
            {/* END epc_category */}

            {/* greenhouse_emissions, not_applicable, empty */}
            {greenHouseEmissions && (
              <div className="o-grid__item u-2-of-4 c-key-number">
                <div className="c-key-number__value">
                  {greenHouseEmissions === 'empty' ||
                  greenHouseEmissions === 'not_applicable' ? (
                    <FormattedMessage
                      id={`legal.value.${greenHouseEmissions}`}
                    />
                  ) : (
                    greenHouseEmissions
                  )}{' '}
                </div>
                <div className="c-key-number__label">
                  <FormattedMessage id="legal.energy.greenhouse_emissions.label" />
                </div>
              </div>
            )}
            {/* END greenhouse_emissions */}
          </div>
        ) : null}
      </div>
    </Panel>
  )
}

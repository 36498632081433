import React from 'react'
import { FormattedMessage } from 'react-intl'

export const FeatureItem = ({ info, description }) => {
  return (
    <li className="c-feature">
      <span></span>
      <span className="c-feature__description">
        <FormattedMessage id={description} />
      </span>
      {info && <span className="c-feature__info truncate">{info}</span>}
    </li>
  )
}

import React from 'react'
import {
  InstantSearch,
  ExperimentalConfigureRelatedItems as ConfigureRelatedItems,
  connectInfiniteHits,
} from 'react-instantsearch-dom'
import { FormattedMessage } from 'react-intl'
import algoliasearch from 'algoliasearch/lite'
import PropertyCard from '../property-card'

const agencyId = process.env.GATSBY_AGENCY_ID

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export function RecommendationsPanel({ property }) {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${agencyId}:properties`}
    >
      <ConfigureRelatedItems
        hit={{
          objectID: property.id,
          ...property,
        }}
        matchingPatterns={{
          type: {
            score: 1,
          },
          negotiation: {
            score: 2,
          },
        }}
        transformSearchParameters={(searchParameters) => {
          return {
            ...searchParameters,
            facetFilters: [
              ...searchParameters.facetFilters,
              `type:${property.type}`,
              `negotiation:${property.negotiation}`,
              `attributes.location.postal_code:${property.attributes.location.postal_code}`,
            ],
          }
        }}
      />
      <Hits />
    </InstantSearch>
  )
}

const Hits = connectInfiniteHits(function ({ hits }) {
  if (hits.length === 0) {
    return null
  }
  return (
    <div className="c-row c-row--beta c-row--bordered print-display-none">
      <div className="o-container">
        <div className="o-grid o-grid--gutter">
          <div className="o-grid__item">
            <h2 className="section-title">
              <FormattedMessage id="you-may-also-like" />
            </h2>
          </div>
        </div>
        <div className="u-css-grid u-grid-cols-1-of-3-bp3 u-grid-gap">
          {hits.slice(0, 3).map((hit) => {
            return <PropertyCard key={hit.objectID} property={hit} />
          })}
        </div>
      </div>
    </div>
  )
})

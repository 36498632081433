import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Panel from '../panel'
import Link from '../localized-link'
import { PropertyPrice } from './property-price'
import { replaceStatus } from '../../utils/helpers'

const TOGGLE_SORT = 'TOGGLE_SORT'

function sortReducer(state, action) {
  switch (action.type) {
    case TOGGLE_SORT: {
      const { field } = action
      if (state.field !== field) {
        return {
          field,
          direction: 'asc',
        }
      } else {
        return {
          ...state,
          direction: state.direction === 'asc' ? 'desc' : 'asc',
        }
      }
    }
    default:
      throw new Error('Invalid action type' + action.type)
  }
}

export function UnitsPanel({ property }) {
  if (property.internal_type !== 'project') {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="units" />}>
      <UnitsTable project={property} />
    </Panel>
  )
}

export function OtherUnitsInProjectPanel({ property }) {
  if (property.internal_type !== 'unit') {
    return null
  }

  if (property.project === null) {
    return null
  }

  // if we have only one unit,
  // it is the one that we are currently looking at
  // so we dont have other units on the project
  if (!property.project.properties || property.project.properties.length <= 1) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="project.units_in_project.title" />}>
      <UnitsTable
        project={{
          ...property.project,
          // exclude the current unit from the table
          properties: property.project.properties.filter(
            (unit) => unit.id !== property.id
          ),
        }}
      />
    </Panel>
  )
}

/// elements
function UnitsTable({ project }) {
  const showFloorsColumn = [
    'apartment',
    'office',
    'parking',
    'commercial',
  ].includes(project.type)

  const showAreasColumn = project.type === 'land'

  const showBedroomsColumn = ['apartment', 'house'].includes(project.type)

  const [sort, dispatch] = React.useReducer(sortReducer, {
    field: showFloorsColumn
      ? 'attributes.location.floor'
      : 'attributes.price.published_price.amount',
    direction: 'asc',
  })

  const sortedUnits = _.orderBy(
    project.properties,
    [sort.field],
    [sort.direction]
  )

  return (
    <table className="c-table c-table-stacked c-table--sortable">
      <thead>
        <tr>
          {showFloorsColumn && (
            <th role="column-header">
              <span
                className="flex items-center"
                onClick={() => {
                  dispatch({
                    type: TOGGLE_SORT,
                    field: 'attributes.location.floor',
                  })
                }}
              >
                <FormattedMessage id="project.units.table.headers.floor" />
                <SortIcon
                  active={sort.field === 'attributes.location.floor'}
                  direction={sort.direction}
                />
              </span>
            </th>
          )}
          <th
            role="column-header"
            onClick={() => {
              dispatch({
                type: TOGGLE_SORT,
                field: 'attributes.price.published_price.amount',
              })
            }}
          >
            <span className="flex items-center">
              <FormattedMessage id="project.units.table.headers.price" />{' '}
              <SortIcon
                active={
                  sort.field === 'attributes.price.published_price.amount'
                }
                direction={sort.direction}
              />
            </span>
          </th>
          {showBedroomsColumn && (
            <th
              role="column-header"
              onClick={() => {
                dispatch({
                  type: TOGGLE_SORT,
                  field: 'attributes.structure.bedrooms',
                })
              }}
            >
              <span className="flex items-center">
                <FormattedMessage id="project.units.table.headers.bedrooms" />{' '}
                <SortIcon
                  active={sort.field === 'attributes.structure.bedrooms'}
                  direction={sort.direction}
                />
              </span>
            </th>
          )}
          {showAreasColumn && (
            <th
              role="column-header"
              onClick={() => {
                dispatch({
                  type: TOGGLE_SORT,
                  field: 'attributes.structure.plot_area.size',
                })
              }}
            >
              <span className="flex items-center">
                <FormattedMessage id="project.units.table.headers.area" />{' '}
                <SortIcon
                  active={sort.field === 'attributes.structure.plot_area.size'}
                  direction={sort.direction}
                />
              </span>
            </th>
          )}
          <th role="column-header">
            <span
              className="flex items-center"
              onClick={() => {
                dispatch({
                  type: TOGGLE_SORT,
                  field: 'attributes.status',
                })
              }}
            >
              <FormattedMessage id="project.units.table.headers.status" />{' '}
              <SortIcon
                active={sort.field === 'attributes.status'}
                direction={sort.direction}
              />
            </span>
          </th>
          <th role="column-header"></th>
        </tr>
      </thead>
      <tbody>
        {sortedUnits.map((unit) => {
          return (
            <tr key={unit.id}>
              {showFloorsColumn && (
                <td>
                  <Link
                    to={`/${project.negotiation}/${unit.id}`}
                    className="c-table__cell--link"
                  >
                    <span className="c-table__cell--title">
                      <FormattedMessage id="project.units.table.headers.floor" />
                      :{' '}
                    </span>
                    {unit.attributes.location.floor ?? '-'}
                  </Link>
                </td>
              )}
              <td>
                <Link
                  to={`/${project.negotiation}/${unit.id}`}
                  className="c-table__cell--link"
                >
                  <span className="c-table__cell--title">
                    <FormattedMessage id="project.units.table.headers.price" />:
                  </span>{' '}
                  <PropertyPrice property={unit} />
                </Link>
              </td>
              {showBedroomsColumn && (
                <td>
                  <Link
                    to={`/${project.negotiation}/${unit.id}`}
                    className="c-table__cell--link"
                  >
                    <span className="c-table__cell--title">
                      <FormattedMessage id="project.units.table.headers.bedrooms" />
                      :{' '}
                    </span>
                    {unit.attributes.structure.bedrooms}
                  </Link>
                </td>
              )}
              {showAreasColumn && (
                <td>
                  <Link
                    to={`/${project.negotiation}/${unit.id}`}
                    className="c-table__cell--link"
                  >
                    <span className="c-table__cell--title">
                      <FormattedMessage id="project.units.table.headers.area" />
                      :{' '}
                    </span>
                    {unit.attributes.structure.plot_area?.size ?? '-'}
                  </Link>
                </td>
              )}
              <td>
                <Link
                  to={`/${project.negotiation}/${unit.id}`}
                  className="c-table__cell--link"
                >
                  <span className="c-table__cell--title">
                    <FormattedMessage id="project.units.table.headers.status" />
                    :{' '}
                  </span>
                  <div className="py-2">
                    <StatusCell unit={unit} />
                  </div>
                </Link>
              </td>
              <td className="c-table__cell c-table__cell--position">
                <Link
                  to={`/${project.negotiation}/${unit.id}`}
                  className="c-table__cell--link"
                  onClick={() => {
                    // eslint-disable-next-line
                    window.analytics?.track('Unit Row Clicked', {
                      unitId: unit.id,
                    })
                  }}
                >
                  <svg
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1 13.5c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l5.6-5.6L.6 1.4C.4 1.2.4.9.6.7c.2-.2.5-.2.7 0l6 6c.2.2.2.5 0 .7l-6 6c0 .1-.2.1-.3.1z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </Link>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function SortIcon({ direction, active }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      className="chevron-up w-6 h-6"
      style={{
        width: 20,
        height: 20,
        opacity: active ? 1 : 0.2,
        transformOrigin: '50% 50%',
        transform:
          !active || direction == 'asc' ? 'rotateZ(0)' : 'rotateZ(180deg)',
      }}
    >
      <path
        fillRule="evenodd"
        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

function StatusCell({ unit }) {
  const status = replaceStatus(unit.attributes.status)

  if (['prospect', 'under_contract', 'lost'].includes(status)) {
    return null
  }

  return (
    <span
      className={classNames('px-3 py-2 text-sm border-gray', {
        'bg-theme text-white': ['sold', 'rented'].includes(status),
      })}
    >
      {status === 'available' ? (
        <FormattedMessage
          id={`property.status.${unit.negotiation}.${status}`}
        />
      ) : (
        <FormattedMessage id={`property.status.${status}`} />
      )}
    </span>
  )
}

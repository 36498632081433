import React from 'react'
import Panel from '../panel'
import { FormattedMessage } from 'react-intl'
import MailIcon from '../../assets/img/icons/ico-mail.svg'
import PhoneIcon from '../../assets/img/icons/ico-phone.svg'
import Link from '../localized-link'

export default function NegotiatorCard({ property }) {
  const hasPhoto = property.negotiator.photo?.data?.photo_file_id

  return (
    <Panel title={<FormattedMessage id="your-agent" />}>
      <div className="agent">
        {hasPhoto && (
          <div className="agent__image">
            {/*<Link to={`/negotiator/${property.negotiator.id}`}>*/}
            <img
              src={`https://cdn.sweepbright.com/users/presets/avatar-medium/${property.negotiator.photo?.data?.photo_file_id}`}
              alt=""
            />
            {/*</Link>*/}
          </div>
        )}
        <div className="agent__info">
          <h2 className="agent__name">
            {/*<Link to={`/negotiator/${property.negotiator.id}`}>*/}
            {property.negotiator.first_name} {property.negotiator.last_name}
            {/*</Link>*/}
          </h2>
          <span className="agent__email">
            <i className="o-icon">
              <MailIcon />
            </i>
            <a href={`mailto:${property.negotiator.email}`}>
              {property.negotiator.email}
            </a>
          </span>
          {property.negotiator.phone && (
            <span className="agent__phone">
              <i className="o-icon">
                <PhoneIcon />
              </i>
              <a href={`tel:${property.negotiator.phone}`}>
                {property.negotiator.phone}
              </a>
            </span>
          )}
        </div>
      </div>
    </Panel>
  )
}

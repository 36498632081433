import React from 'react'
import Panel from '../panel'
import { FormattedMessage } from 'react-intl'

export function LegalMentionsFreeFormPanel({ property, locale }) {
  if (property.internal_type === 'project') {
    return null
  }

  const html = property.attributes.legal.legal_mentions?.[locale]
  if (!html) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="legal-mentions-free" />}>
      <div>{html}</div>
    </Panel>
  )
}

import React from 'react'
import Panel from '../panel'
import { FeatureItem } from '../feature'
import { FormattedMessage } from 'react-intl'

export function PermissionsPanel({ property }) {
  if (property.internal_type === 'project') {
    return null
  }

  if (property.type !== 'land') {
    return null
  }

  if (property.attributes.permissions?.length > 0) {
    return (
      <Panel title={<FormattedMessage id="permissions.title" />}>
        <div className="o-grid o-grid--gutter">
          <div className="o-grid__item">
            <ul className="c-feature-list c-feature-list--checked">
              {(property.attributes.permissions ?? []).map((permission) => {
                return (
                  <FeatureItem
                    key={`permissions_${permission}`}
                    description={
                      <FormattedMessage id={`permissions.${permission}`} />
                    }
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </Panel>
    )
  }

  return null
}

import React from 'react'
import Panel from '../panel'
import { Html } from '../html'
import { FormattedMessage } from 'react-intl'

const Description = ({ property, locale }) => {
  const html = property.attributes.description.description[locale]

  if (html) {
    return (
      <Panel title={<FormattedMessage id="description" />}>
        <Html content={html} />
      </Panel>
    )
  }
  return null
}

export default Description

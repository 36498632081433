import React from 'react'
import Panel from '../panel'
import classNames from 'classnames'
import sortBy from 'lodash/sortBy'
import $ from 'jquery'
import 'lightgallery'
import 'lg-thumbnail'
import { FormattedMessage } from 'react-intl'
import 'lightgallery/dist/css/lightgallery.min.css'
import { getPanoramaUrl } from '../../utils/helpers'

export function PhotosPanel({ property }) {
  React.useEffect(() => {
    $('#light-gallery').lightGallery()
  }, [])

  if (!property.attributes.images || property.attributes.images.length === 0) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="photos" />}>
      <div className="o-container c-imagegrid">
        <div className="o-grid" id="light-gallery">
          {sortBy(
            property.attributes.images.filter((img) => !img.private),
            ['ordinal']
          ).map(({ id, equirectangular }) => {
            const previewURL = `${process.env.GATSBY_SWEEPBRIGHT_CDN_URL}/properties/presets/agency-website-thumbnail/${id}`
            const fullURL = equirectangular
              ? getPanoramaUrl(id)
              : `${process.env.GATSBY_SWEEPBRIGHT_CDN_URL}/properties/presets/agency-website/${id}`

            return (
              <a
                key={id}
                className="o-grid__item u-1-of-2 u-1-of-3-bp3 u-1-of-4-bp4"
                href={fullURL}
                data-iframe={equirectangular}
              >
                <span
                  className={classNames(
                    'property__image property__image--spaced',
                    {
                      'property__image--panorama': equirectangular,
                    }
                  )}
                  style={{
                    backgroundImage: `url("${previewURL}")`,
                  }}
                >
                  <img src={previewURL} />
                </span>
              </a>
            )
          })}
        </div>
      </div>
    </Panel>
  )
}

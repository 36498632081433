import React from 'react'
import Panel from '../panel'
import { FeatureItem } from '../feature'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { TransChoice } from '../trans-choice'

const numericFeatures = {
  'attributes.structure.bedrooms': 'plurals.bedrooms',
  'attributes.structure.bathrooms': 'plurals.bathrooms',
  'attributes.structure.toilets': 'plurals.toilets',
  'attributes.structure.floors': 'plurals.floors',
  'attributes.structure.showrooms': 'plurals.showrooms',
  'attributes.structure.shower_rooms': 'plurals.shower_rooms',
  'attributes.structure.manufacturing_areas': 'plurals.manufacturing_areas',
  'attributes.structure.storage_rooms': 'plurals.storage_rooms',

  'attributes.building.units_of_building': 'plurals.building.units_of_building',
  'attributes.building.construction.residential_lots':
    'plurals.building.construction.residential_lots',
  'attributes.building.number_of_floor_building':
    'plurals.building.number_of_floor_building',
}

const FeaturesPanel = ({ property }) => {
  if (property.internal_type === 'project') {
    return null
  }

  const features = [
    ...(property.attributes.shapes ?? []).map((shape) => {
      return (
        <FeatureItem
          key={`shapes_${shape}`}
          description={<FormattedMessage id={`shapes.${shape}`} />}
        />
      )
    }),
    ...Object.entries(numericFeatures).map(([featurePath, translationKey]) => {
      const quantity = _.get(property, featurePath)
      if (quantity) {
        return (
          <FeatureItem
            description={
              <>
                <b>{quantity}</b>{' '}
                <TransChoice count={quantity} id={translationKey} />
              </>
            }
          />
        )
      }
      return null
    }),
    ...(_.get(property, 'attributes.amenities') || []).map((amenity) => (
      <FeatureItem description={`amenities.${amenity}`} />
    )),
    ...(_.get(property, 'attributes.features.comfort') || []).map((feature) => (
      <FeatureItem description={`features.comfort.${feature}`} />
    )),
    ...(
      _.get(property, 'attributes.features.security') || []
    ).map((feature) => (
      <FeatureItem description={`features.security.${feature}`} />
    )),
  ].filter(Boolean)

  if (features.length === 0) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="features.title" />}>
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item">
          <ul
            className={`c-feature-list c-feature-list--checked c-feature-list--split-${
              features.length > 5 ? 3 : 2
            }`}
          >
            {features}
          </ul>
        </div>
      </div>
    </Panel>
  )
}

export default FeaturesPanel

import React from 'react'
import Panel from '../panel'
import { Embedly } from '../embedly'
import { FormattedMessage } from 'react-intl'
import ArrowRightIcon from '../../assets/img/icons/arrow-right.svg'

export default function VideoPanel({ property }) {
  if (!property.attributes.video) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="video" />}>
      <div className="c-video">
        <Embedly
          url={property.attributes.video}
          linkLabel={
            <>
              <ArrowRightIcon />{' '}
              <FormattedMessage
                id="component.video-panel.click-here-to-view"
                defaultMessage="Click here to view"
              />
            </>
          }
        />
      </div>
    </Panel>
  )
}

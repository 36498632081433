import React from 'react'
import Panel from '../panel'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

export function DocumentsPanel({ property }) {
  if (property.internal_type === 'project') {
    return null
  }

  const documents = (property.attributes.documents ?? [])
    .filter((doc) => !doc.private)
    .map((doc) => {
      return {
        ...doc,
        thumbnail_url: `${process.env.GATSBY_SWEEPBRIGHT_CDN_URL}/documents/presets/thumbnail/${doc.id}`,
        url: `${process.env.GATSBY_SWEEPBRIGHT_CDN_URL}/documents/presets/original/${doc.id}`,
      }
    })

  if (documents.length === 0) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="documents.title" />}>
      <div className="o-container c-documentgrid">
        <div className="o-grid o-grid--gutter">
          {documents.map((doc) => {
            return (
              <div className="o-grid__item u-1-of-2-bp3 c-documentgrid__item">
                <div className="o-grid o-grid--gutter">
                  <div className="o-grid__item u-3-of-5 u-push-1-of-5 u-1-of-1-bp3 u-1-of-2-bp3 u-push-reset-bp3">
                    <p className="c-documentgrid__image">
                      <a href={doc.url}>
                        <span
                          className="property__image property__image--bordered"
                          style={{
                            backgroundImage: `url("${doc.thumbnail_url}")`,
                          }}
                        >
                          <img
                            src={doc.thumbnail_url}
                            alt={doc.description || doc.filename}
                          />
                        </span>
                      </a>
                    </p>
                  </div>
                  <div className="o-grid__item u-3-of-5 u-push-1-of-5 u-1-of-1-bp3 u-1-of-2-bp3 u-push-reset-bp3">
                    <p className="c-documentgrid__description">
                      {doc.description || doc.filename}
                    </p>
                    <p>
                      <a href={doc.url} download={doc.filename}>
                        <i className="o-icon">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M19.977,9.04c0.015,-0.188 0.023,-0.366 0.023,-0.54c0,-4.136 -3.364,-7.5 -7.5,-7.5c-2.784,0 -5.361,1.576 -6.646,4.04c-3.306,0.326 -5.854,3.109 -5.854,6.46c0,3.584 2.916,6.5 6.5,6.5l3,0c0.276,0 0.5,-0.224 0.5,-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5l-3,0c-3.033,0 -5.5,-2.468 -5.5,-5.5c0,-2.918 2.285,-5.327 5.202,-5.484c0.184,-0.01 0.347,-0.12 0.425,-0.286c1.07,-2.266 3.376,-3.73 5.873,-3.73c3.584,0 6.5,2.916 6.5,6.5c0,0.282 -0.024,0.58 -0.076,0.937c-0.021,0.145 0.06,0.288 0.156,0.398c0.096,0.11 0.267,0.16 0.42,0.165c1.93,0 3.5,1.57 3.5,3.5c0,1.93 -1.57,3.5 -3.5,3.5l-5,0c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.276 0.224,0.5 0.5,0.5l5,0c2.481,0 4.5,-2.019 4.5,-4.5c0,-2.296 -1.729,-4.196 -4.023,-4.46Z"></path>
                              <path d="M14,20.001c0.128,0 0.256,0.049 0.354,0.146c0.195,0.195 0.195,0.512 0,0.707l-2,2c-0.046,0.046 -0.102,0.083 -0.163,0.108c-0.122,0.051 -0.26,0.051 -0.382,0c-0.062,-0.025 -0.117,-0.062 -0.163,-0.108l-2,-2c-0.195,-0.195 -0.195,-0.512 0,-0.707c0.098,-0.097 0.226,-0.146 0.354,-0.146c0.128,0 0.256,0.049 0.354,0.146l1.146,1.146l0,-11.792c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5l0,11.793l1.146,-1.146c0.098,-0.098 0.226,-0.147 0.354,-0.147Z"></path>
                            </g>
                          </svg>
                        </i>{' '}
                        <FormattedMessage id="plans.download" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Panel>
  )
}

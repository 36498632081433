import React from 'react'
import { FormattedMessage } from 'react-intl'

export function UnitCount({ project }) {
  const count = project.properties?.length || 0
  if (count === 0) {
    return <FormattedMessage id="project.units.empty" />
  }
  if (count === 1) {
    // I know, this sound wrong, but thats how it is done
    // in the old agency website, maybe some day
    // when it is gone, we can change this
    return <FormattedMessage id="project.units.label.zero" />
  }

  return (
    <span>
      {project.properties.length}{' '}
      <FormattedMessage id="project.units.label.one" />
    </span>
  )
}

import React from 'react'
import classNames from 'classnames'

const Panel = ({ title, className = '', hideForPrint = false, children }) => {
  return (
    <article
      className={classNames('panel', { 'print-display-none': hideForPrint })}
    >
      <h2 className="panel__title">{title}</h2>
      <div className={classNames('panel__body', className)}>{children}</div>
    </article>
  )
}

export default Panel

import React from 'react'
import { StaticGoogleMap, Marker } from 'react-static-google-map'

// project elements
export function StaticMap({ property }) {
  const location = `${property.attributes.location.city} ${property.attributes.location.postal_code} ${property.attributes.location.country}`
  return (
    <StaticGoogleMap
      size="640x320"
      className="w-full object-cover"
      apiKey={process.env.GATSBY_GOOGLE_STATIC_MAPS_API_KEY}
      center={location}
      zoom={16}
    >
      <Marker location={location} />
    </StaticGoogleMap>
  )
}

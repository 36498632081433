import React from 'react'
import { graphql } from 'gatsby'
import Loadable from 'react-loadable'
import Layout from '../components/layout'
import Breadcrum from '../components/breadcrum'
import PropertyHeader from '../components/property-header'
import FeaturesPanel from '../components/property/features-panel'
import DescriptionPanel from '../components/property/description'
import PriceAndCondition from '../components/property/price-and-condition'
import LifeAnnuity from '../components/property/life-annuity'
import {
  OtherUnitsInProjectPanel,
  UnitsPanel,
} from '../components/property/units-panel'
import { PhotosPanel } from '../components/property/photos-panel'
import VideoPanel from '../components/property/video-panel'
import VirtualTourPanel from '../components/property/virtual-tour-panel'
import { AreaPanel } from '../components/property/area-panel'
import { CostPanel } from '../components/property/cost-panel'
import { PermissionsPanel } from '../components/property/permissions-panel'
import { PlansPanel } from '../components/property/plans-panel'
import { DocumentsPanel } from '../components/property/documents-panel'
import { LegalMentionsPanel } from '../components/property/legal-mentions-panel'
import { LegalMentionsFreeFormPanel } from '../components/property/legal-mentions-freeform-panel'
import { EnergyPanel } from '../components/property/energy-panel'
import { PropertySidebar } from '../components/property/property-sidebar'
import { PropertySEO } from '../components/property/property-seo'
import { ProjectSidebar } from '../components/property/project-sidebar'
import { RecommendationsPanel } from '../components/property/recomendations-panel'
import { EstimatedEnergyCosts } from '../components/property/estimated-energy-costs'

// we use React Loadable here
// not for performance purposes
// but to avoid the dates from SSR
// to  be shown which can be outdated or
// in a different timezone
const AuctionPanel = Loadable({
  loader: () => import('../components/property/auction-panel'),
  loading: () => null,
})

const OpenHomesPanel = Loadable({
  loader: () => import('../components/property/oh-panel'),
  loading: () => null,
})

export default (props) => {
  const { locale } = props.pageContext
  const property = props.data.property
  const { internal_type } = property

  return (
    <Layout {...props}>
      <PropertySEO property={property} locale={locale} />
      <Breadcrum path={props.location.pathname} property={property} />
      <PropertyHeader property={property} locale={locale} />
      <div className="c-row c-row--beta">
        <div className="o-container">
          <div className="o-grid o-grid--gutter">
            <div className="o-grid__item u-3-of-5-bp3 u-1-of-1-print">
              <OpenHomesPanel property={property} />
              <AuctionPanel property={property} />
              <VideoPanel property={property} />
              <VirtualTourPanel property={property} />
              <FeaturesPanel property={property} />
              <AreaPanel property={property} />
              <PermissionsPanel property={property} />
              <EnergyPanel property={property} />
              <PriceAndCondition property={property} />
              <LifeAnnuity property={property} />
              <DescriptionPanel property={property} locale={locale} />
              <UnitsPanel property={property} locale={locale} />
              <CostPanel property={property} locale={locale} />
              <PhotosPanel property={property} />
              <PlansPanel property={property} />
              <DocumentsPanel property={property} />
              <LegalMentionsPanel property={property} />
              <LegalMentionsFreeFormPanel property={property} locale={locale} />
              <EstimatedEnergyCosts property={property} />
              <OtherUnitsInProjectPanel property={property} locale={locale} />
              <RecommendationsPanel property={property} />
            </div>
            <div className="o-grid__item u-2-of-5-bp3 u-1-of-1-print">
              {internal_type !== 'project' ? (
                <PropertySidebar property={property} />
              ) : (
                <ProjectSidebar project={property} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  fragment UnitsTableRow on Property {
    id
    negotiation
    type
    internal_type
    project_id
    publish_price
    attributes {
      status
      structure {
        plot_area {
          size
        }
        bedrooms
      }
      price {
        published_price {
          amount
          currency
        }
      }
      location {
        floor
      }
    }
  }
  fragment PropertyCard on Property {
    id
    type
    internal_type
    negotiation
    publish_price
    publish_location
    header_image {
      id
      equirectangular
    }
    _geoloc {
      lat
      lng
    }
    properties {
      id
      attributes {
        price {
          published_price {
            amount
            currency
          }
        }
      }
    }
    attributes {
      status
      structure {
        bedrooms
      }
      price {
        published_price {
          amount
          currency
        }
      }
      description {
        title {
          en
          nl
          fr
          es
        }
      }
      location {
        city
        postal_code
        country
        geo {
          latitude
          longitude
        }
      }
      legal {
        energy {
          e_level
          epc_value
          epc_category
          total_epc_value
          epc_reference
        }
      }
      settings {
        auction {
          start_date
        }
        open_homes {
          id
          start_date
          end_date
        }
      }
    }
  }

  fragment PropertyData on Property {
    id
    type
    internal_type
    negotiation
    published_at
    appointment_service_url
    publish_price
    publish_location
    header_image {
      id
      equirectangular
    }
    negotiator {
      id
      first_name
      last_name
      email
      phone
      photo {
        data {
          photo_file_id
        }
      }
    }
    project {
      id
      type
      negotiation
      attributes {
        description {
          title {
            en
            fr
            nl
            es
          }
        }
      }
      properties {
        ...UnitsTableRow
      }
    }
    attributes {
      status
      description {
        title {
          en
          nl
          fr
          es
        }
        description {
          nl
          en
          nl
          fr
          es
        }
      }
      price {
        yearly_budgeted_building_costs {
          amount
          currency
        }
        property_tax {
          amount
          currency
        }
        recurring_costs {
          amount
          currency
        }
        published_price {
          amount
          currency
        }
        guarantee {
          amount
          currency
        }
        inventory_report_cost {
          amount
          currency
        }
        reference_rent {
          amount
          currency
        }
        base_rent {
          amount
          currency
        }
        rent_supplement {
          amount
          currency
        }
        life_annuity {
          advance {
            amount
            currency
          }
          applicable
          monthly_rent {
            amount
            currency
          }
          maximum_duration
        }
        costs {
          en
          nl
          fr
          es
        }
        taxes {
          en
          nl
          fr
          es
        }
      }
      images {
        id
        ordinal
        filename
        private
        equirectangular
      }
      video
      virtual_tour
      location {
        geo {
          latitude
          longitude
        }
        city
        street
        country
        formatted_agency
        postal_code
        floor
        # borough_or_district
      }
      structure {
        bedrooms
        bathrooms
        toilets
        floors
        showrooms
        shower_rooms
        manufacturing_areas
        storage_rooms
        liveable_area {
          size
          size_description
          units
        }
        plot_area {
          size
          size_description
          units
        }
        gross_area {
          size
          size_description
          units
        }
        loi_carrez_area {
          size
          size_description
          units
        }
        rooms {
          id
          size
          type
          units
          size_description
        }
      }
      settings {
        agency_commission {
          buyer {
            fixed_fee
            percentage
          }
          seller {
            fixed_fee
            percentage
          }
        }
      }
      legal {
        legal_mentions {
          en
          nl
          fr
          es
        }
        regulations {
          name
          value
          stringValue
        }
        energy {
          greenhouse_emissions
          dpe
          e_level
          epc_value
          epc_category
          total_epc_value
          epc_reference
          co2_emissions
          report_fuel_tank
          report_electricity_gas
          dpe_date
          estimated_energy_costs {
            year
            minimum {
              amount
            }
            maximum {
              amount
            }
          }
        }
        property_and_land {
          flood_risk
          flood_risk_plot_score
          flood_risk_building_score
          land_use_designation
          cadastral_income
        }
      }
      floor_plans {
        id
        description
        filename
        private
      }
      documents {
        id
        description
        filename
        private
      }
      features {
        energy
        ecology
        heating_cooling
        comfort
        security
      }
      general_condition
      amenities
      shapes
      permissions
      settings {
        auction {
          start_date
        }
        open_homes {
          id
          start_date
          end_date
        }
      }
      building {
        units_of_building
        construction {
          residential_lots
        }
        number_of_floor_building
      }
    }
  }
  query($propertyId: String) {
    property(id: { eq: $propertyId }) {
      ...PropertyData
      properties {
        ...PropertyData
      }
    }
  }
`

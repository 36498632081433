import React from 'react'
import Panel from '../panel'
import _ from 'lodash'
import { FeatureItem } from '../feature'
import { FormattedMessage } from 'react-intl'

export function EstimatedEnergyCosts({ property }) {
  if (property.internal_type === 'project') {
    return null
  }

  const object = property.attributes.legal?.energy?.estimated_energy_costs ?? {}

  const sections = [
    object?.minimum?.amount && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.estimated-energy-costs.minimum.label" />
        }
        info={object?.minimum?.amount}
      />
    ),
    object?.maximum?.amount && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.estimated-energy-costs.maximum.label" />
        }
        info={object?.maximum?.amount}
      />
    ),
    object?.year && (
      <FeatureItem
        description={
          <FormattedMessage id="legal.estimated-energy-costs.year.label" />
        }
        info={object?.year}
      />
    ),
  ].filter(Boolean)

  if (sections.length === 0) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="legal.estimated-energy-costs" />}>
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item u-4-of-4-bp4">
          <ul className="c-feature-list c-feature-list--striped">{sections}</ul>
        </div>
      </div>
    </Panel>
  )
}

import React from 'react'
import Panel from '../panel'
import { Embedly } from '../embedly'
import { FormattedMessage } from 'react-intl'
import ArrowRightIcon from '../../assets/img/icons/arrow-right.svg'

export default function VirtualTourPanel({ property }) {
  if (!property.attributes.virtual_tour) {
    return null
  }

  return (
    <Panel title={<FormattedMessage id="virtual_tour" />}>
      <div className="c-video">
        <Embedly
          url={property.attributes.virtual_tour}
          linkLabel={
            <>
              <ArrowRightIcon />{' '}
              <FormattedMessage
                id="component.virtual-tour-panel.click-here-to-enter"
                defaultMessage="Click here to enter virtual tour"
              />
            </>
          }
        />
      </div>
    </Panel>
  )
}
